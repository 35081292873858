<template>
  <div>
    <vue-apex-charts
      :options="getChartOptions"
      :series="series"
      height="100%"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "DonutChart",
  components: {VueApexCharts},
  props: {
    labels: {
      type: Array,
      required: false
    },
    series: {
      type: Array,
      required: true
    },
    fillImages: {
      type: Array,
    },
    colors: {
      type: Array,
      default: () => [
        '#7E9036', '#F9844A', '#d7484b', '#4D908E', '#F9C74F', '#277DA1',
        '#F8961E', '#90BE6D', '#F3722C', '#43AA8B', '#577590',
      ]
    },
    legendPosition: {
      type: String,
      default: "bottom",
      validator: val => ["top", "right", "bottom", "left"].includes(val)
    },
    customFormatter: {
      type: Function,
      default: null
    }
  },
  computed: {
    getChartOptions() {
      let options = {
        chart: {
          type: 'donut'
        },
        labels: this.labels,
        legend: {
          position: this.legendPosition
        }
      };

      if (this.colors) {
        this.$set(options, 'colors', this.colors);
      }

      if (this.fillImages) {
        this.$set(options, 'fill', {
          type: 'image',
          opacity: 0.85,
          image: {
            src: this.fillImages,
            width: 25,
            imagedHeight: 25
          }
        });
      }

      if (this.customFormatter !== null) {
        this.$set(options, 'dataLabels', {
          formatter: this.customFormatter
        });
      }

      return options;
    }
  },
}
</script>

<style scoped>

</style>